/**
 * DESCRIPTION: This stylesheet contains the styling for the navbar component (Navbar.tsx).
 *
 * Author: Dean Longstaff
 */
/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

.sticky {
  background-color: #1b1a2ea9;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
  backdrop-filter: blur(15px);
}

.navbar {
  position: fixed;
  transition: all 0.3s ease-out 0s;
  padding: 0.3rem 2rem;
  font-size: 1.2rem;
}

.navbar-toggler {
  position: relative;
  background-color: transparent;
  border-color: transparent;
}

.navbar-toggler span {
  display: block;
  background-color: var(--primary-color);
  height: 4px;
  width: 27px;
  margin-top: 5px;
  margin-bottom: 5px;
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    background-color: #181a27;
  }

  .navbar-nav .nav-item a::after {
    display: none;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em;
  width: 2.5em;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem;
  padding-left: 1rem;
}

.nav-link {
  padding: 0.8rem 1rem;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: var(--secondary-color);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover {
  cursor: pointer;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.navbar-nav .nav-item .active-nav-link::after {
  width: 100%;
  background: var(--primary-color);
}

.github-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.github-btn-inner {
  line-height: 1.4em !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.github-btn-inner:hover {
  transform: translateY(-2px);
  background-color: #a24dd386;
  border-color: #a24dd386;
}
.github-btn-inner::after {
  display: none !important;
}
