/**
 * DESCRIPTION: This stylesheet contains the main styling for the app.
 *
 * Author: Dean Longstaff
 */
/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/* ----- Import fonts ----- */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/* ----- Main CSS and Global Variables ----- */

body {
  margin: 0;
  font-family: "Raleway", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.543),
    rgba(12, 10, 22, 0.863)
  );

  --primary-color: #007bff;
  --secondary-color: #3fe2ff;

  text-align: center;
}

#tsparticles {
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

button:focus {
  box-shadow: none;
}

.main-heading {
  color: white;
  font-size: 2.3em;
  font-weight: 500;
  padding-top: 10px;
}

.secondary-heading {
  color: white;
  font-size: 1.6em;
  font-weight: 300;
  padding-top: 10px;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/* ----- Scrollbar CSS ----- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232323;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  border-radius: 12px;
}
