/**
 * DESCRIPTION: This stylesheet contains the styling for the about me component of the home page (AboutMe.tsx).
 *
 * Author: Dean Longstaff
 */
/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

.aboutme-section {
  position: relative;
  padding-bottom: 70px;
  padding-top: 70px;
  background-image: var(--image-gradient), url(../../assets/images/me-diy.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutme-description {
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.aboutme-body {
  padding-top: 50px;
  font-size: 1.2em;
  text-align: left;
}

.ryobi {
  color: #ccda46;
}
