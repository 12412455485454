/**
 * DESCRIPTION: This stylesheet contains the styling for the welcome component of the home page (Welcome.tsx).
 *
 * Author: Dean Longstaff
 */
/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* ----- Welcome Section ----- */
/* Padding if on tablet/laptop */
@media screen and (min-width: 768px) {
  .welcome-header {
    padding-top: 80px;
  }

  .welcome-section {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.welcome-section {
  position: relative;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--image-gradient),
    url(../../assets/images/background.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.welcome-content {
  padding: 9rem 0 2rem;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em;
  padding-left: 50px;
}

.heading-name {
  font-size: 2.5em;
  padding-left: 45px;
}

.Typewriter__wrapper {
  font-size: 2.2em;
  color: var(--primary-color);
  font-weight: 600;
}

.Typewriter__cursor {
  font-size: 2.4em;
  color: var(--primary-color);
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em;
    font-weight: 500;
    position: absolute;
  }

  .Typewriter__cursor {
    display: none;
  }
}
